<template>
  <div class="list">
    <van-form ref="vanForm" @submit="onSubmit">
      <van-field name="sex" label="新生儿无身份证" label-width="110">
        <template #input>
          <van-radio-group v-model="formData.isNew" direction="horizontal">
            <van-radio
              v-for="(item, index) in newOptions"
              :key="index"
              :name="item.value"
              @click="changeNew"
            >
              {{ item.text }}
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="formData.name"
        name="name"
        label="姓名"
        placeholder="请输入姓名"
        :rules="[{ required: true, message: '' }]"
      />
      <van-field
        v-if="formData.isNew === 1"
        v-model="formData.number"
        name="number"
        label="就诊卡号"
        placeholder="一经填写无法修改，请谨慎填写"
        :rules="[{ required: true, message: '请输入就诊卡号' }]"
      />
      <van-field
        v-if="formData.isNew === 0"
        readonly
        clickable
        name="idType"
        :value="idTypeName"
        label="证件类型"
        placeholder="选择证件类型"
        :rules="[{ required: true, message: '请选择证件类型' }]"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          value-key="text"
          :columns="idTypeOptions"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        v-if="formData.isNew === 0"
        v-model="formData.card_No"
        name="cardNo"
        label="证件号码"
        placeholder="一经填写无法修改，请谨慎填写"
        :rules="
          formData.isNew === 1
            ? []
            : [
                { required: true, message: '请填写证件号码' },
                { validator, message: '证件号码格式错误！' },
              ]
        "
        @blur="getInfo"
      />
      <van-field name="sex" label="性别">
        <template #input>
          <van-radio-group v-model="formData.sex" direction="horizontal">
            <van-radio
              v-for="(item, index) in sexOptions"
              :key="index"
              :name="item.value"
            >
              {{ item.text }}
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="formData.mobile"
        name="mobile"
        label="手机号码"
        placeholder="请输入手机号码"
        :rules="[
          { required: true, message: '' },
          {
            validator: (value) => {
              return /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[0-9])\d{8}$/.test(
                value
              )
            },
            message: '请输入正确格式的手机号码',
            trigger: 'onBlur',
          },
        ]"
      />
      <van-field
        v-model="formData.code"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
        :rules="[{ required: true, message: '' }]"
        maxlength="6"
      >
        <template #button>
          <van-button
            v-if="!showCountDown"
            size="small"
            type="primary"
            native-type="button"
            @click="sendCode"
          >
            获取验证码
          </van-button>
          <van-button
            v-if="showCountDown"
            size="small"
            type="primary"
            native-type="button"
            disabled
          >
            <van-count-down
              ref="countDown"
              millisecond
              :time="61000"
              :auto-start="true"
              format="ss"
              @finish="finish"
            />
          </van-button>
        </template>
      </van-field>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit">
          保存
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
  import { Toast } from 'vant'
  import { getPersonInfo } from '@/util/validate'
  import { pageInitDic } from '@/api/com/dic/dic'
  import { sendMessage } from '@/api/com/validate/validate'
  import { addAccount, getUserInfo } from '@/api/wechat/account/account'
  import store from '@/store'

  export default {
    data() {
      return {
        showPicker: false,
        showCountDown: false,
        idTypeName: '',
        formData: {
          name: '',
          mobile: '',
          openId: '',
          card_No: '',
          sex: null,
          idType: 2,
          code: '',
          isNew: 0,
          number: '',
        },
        //证件类型
        idTypeOptions: [],
        sexOptions: [],
        newOptions: [
          {
            value: 1,
            text: '是',
          },
          {
            value: 0,
            text: '否',
          },
        ],
      }
    },

    async created() {
      await this.pageInitDicOne()
      this.formData.openId = store.getters['user/openId']
      this.idTypeName = this.idTypeOptions[0].text
    },

    methods: {
      async onSubmit() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在保存...',
          forbidClick: true,
        })
        const { success, msg } = await addAccount(this.formData)
        Toast.clear()
        Toast(msg)
        if (success) {
          await this.getUserInfo()
          await this.$router.replace('/account')
        }
      },

      async getUserInfo() {
        const { data } = await getUserInfo({ OpenId: this.formData.openId })
        await store.dispatch('user/getUserInfo', JSON.stringify(data))
      },

      async pageInitDicOne() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await pageInitDic()
        this.idTypeOptions = data.find((item) => item.key === '1').options
        this.sexOptions = data.find((item) => item.key === '2').options
        Toast.clear()
      },

      //选择证件类型
      onConfirm(value) {
        this.idTypeName = value.text
        this.formData.idType = value.value
        this.formData.card_No = ''
        this.showPicker = false
      },

      changeNew() {
        this.formData.idType = this.formData.isNew === 1 ? 1 : 2
        this.$refs['vanForm'].resetValidation('cardNo')
      },

      //短信发送
      async sendCode() {
        this.$refs['vanForm']
          .validate('mobile')
          .then(async () => {
            this.showCountDown = true
            const { success, msg } = await sendMessage({
              Phone: this.formData.mobile,
              OpenId: this.formData.openId,
            })
            if (!success) {
              this.finish()
            }
            Toast(msg)
          })
          .catch(() => {
            Toast.fail('请输入正确的手机号码')
          })
      },

      //倒计时结束
      finish() {
        this.showCountDown = false
      },

      validator(val) {
        switch (this.formData.idType) {
          case 2:
            /*  const card15 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/
            const cardWGR15 = /^[A-Z]{3}[0-9]{12}$/
            const card18 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
            return card15.test(val) || card18.test(val) || cardWGR15.test(val)*/
            return this.IdentityIDCard(val)
            break

          case 3:
            const hk = /^[HMhm]\d{8}$/
            console.log(hk.test(val))
            return hk.test(val)
            break

          case 4:
            const tw = /((?=.*[0-9]))(^[A-Za-z0-9]{8}$)/
            return tw.test(val)
            break

          case 5:
            const foreign = /^[A-Z0-9]{9}$/i
            return foreign.test(val)
            break
        }
      },

      IdentityIDCard(code) {
        //身份证号前两位代表区域
        var city = {
          11: '北京',
          12: '天津',
          13: '河北',
          14: '山西',
          15: '内蒙古',
          21: '辽宁',
          22: '吉林',
          23: '黑龙江 ',
          31: '上海',
          32: '江苏',
          33: '浙江',
          34: '安徽',
          35: '福建',
          36: '江西',
          37: '山东',
          41: '河南',
          42: '湖北 ',
          43: '湖南',
          44: '广东',
          45: '广西',
          46: '海南',
          50: '重庆',
          51: '四川',
          52: '贵州',
          53: '云南',
          54: '西藏 ',
          61: '陕西',
          62: '甘肃',
          63: '青海',
          64: '宁夏',
          65: '新疆',
          71: '台湾',
          81: '香港',
          82: '澳门',
          91: '国外 ',
        }
        //身份证格式正则表达式
        const cardWGR15 = /^[A-Z]{3}[0-9]{12}$/

        var idCardReg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i
        var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/

        var errorMess = '' //错误提示信息
        var isPass = true //身份证验证是否通过（true通过、false未通过）

        console.log(!code)
        console.log(!regIdCard.test(code) && !cardWGR15.test(code))
        //如果身份证不满足格式正则表达式
        if (!code || (!regIdCard.test(code) && !cardWGR15.test(code))) {
          errorMess = '您输入的身份证号格式有误！'
          isPass = false
        }

        //区域数组中不包含需验证的身份证前两位
        else if (!city[code.substr(0, 2)] && !code.length == 18) {
          errorMess = '您输入的身份证地址编码有误！'
          isPass = false
        } else {
          //18位身份证需要验证最后一位校验位
          if (code.length == 18) {
            code = code.split('')
            //∑(ai×Wi)(mod 11)
            //加权因子
            var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
            //校验位
            var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
            var sum = 0
            var ai = 0
            var wi = 0
            for (var i = 0; i < 17; i++) {
              ai = code[i]
              wi = factor[i]
              sum += ai * wi
            }
            var last = parity[sum % 11]
            if (parity[sum % 11] != code[17]) {
              errorMess = '18身份证最后一位校验位验证有误！'
              isPass = false
            }
          }
        }
        var returnParam = {
          'errorMess ': errorMess,
          'isPass ': isPass,
        }
        return isPass
      },

      //获取个人信息
      getInfo(e) {
        if (this.formData.idType === 2) {
          if (this.validator(e.target.value)) {
            let info = getPersonInfo(e.target.value)
            this.formData.sex = info.sex
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .van-contact-list ::v-deep {
    .van-button--danger {
      background-color: #1989fa;
      border: 0;
    }
    .van-contact-list__edit {
      display: none;
    }
  }
</style>
